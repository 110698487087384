<template>
  <div class="container">
    <div class="form-card">
      <div class="card-head">
        <h4 class="card-title">
          <span>Uygulama Detay</span>
          <i class="icon icon-arrow-right-3"></i>
          {{ formData.name }}
        </h4>
      </div>
      <div class="card-body mb-20">
        <div class="d-flex mb-20">
          <button class="btn btn-filled-primary mr-10">APK İndir</button>
          <button class="btn btn-filled-primary mr-10">ABB İndir</button>
          <button class="btn btn-filled-primary mr-10">IPA İndir</button>
        </div>
        <h4 class="card-sm-title">Store</h4>
        <div class="row">
          <div class="col-md-4">
            <TextTemplate
              label="Store Url"
              :value="formData.ecommerce_store.store_url"
              keyName="formData.ecommerce_store.store_url"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ecommerce_store.store_url.$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ecommerce_store.store_url.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Security Privacy Policy Url"
              :value="formData.ecommerce_store.security_privacy_policy_url"
              keyName="formData.ecommerce_store.security_privacy_policy_url"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ecommerce_store.security_privacy_policy_url.$errors
                  .length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ecommerce_store
                .security_privacy_policy_url.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Support Url"
              :value="formData.ecommerce_store.support_url"
              keyName="formData.ecommerce_store.support_url"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ecommerce_store.support_url.$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ecommerce_store.support_url.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Contact E-mail"
              :value="formData.ecommerce_store.contact_email"
              keyName="formData.ecommerce_store.contact_email"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ecommerce_store.contact_email.$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ecommerce_store.contact_email.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Ecommerce Platform"
              :value="formData.ecommerce_store.ecommerce_platform"
              keyName="formData.ecommerce_store.ecommerce_platform"
              type="selectID"
              :options="ecommerce_platform_list"
              @onChangeText="handleText"
            />
          </div>
          <div class="col-md-4">
            <div
              class="form-group"
              :class="
                v$.formData.ecommerce_store.customer.$errors.length > 0
                  ? 'error'
                  : ''
              "
            >
              <label for="">Müşteri</label>
              <Multiselect
                v-model="formData.ecommerce_store.customer"
                :options="customer_list"
                label="name"
                track-by="name"
                placeholder=""
                selectLabel=""
                selectedLabel=""
                deselectLabel=""
              ></Multiselect>
            </div>
            <span
              v-for="error in v$.formData.ecommerce_store.customer.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
        </div>
      </div>
      <div class="card-body mb-20">
        <h4 class="card-sm-title">İdeasoft</h4>
        <div class="row">
          <div class="col-md-4">
            <TextTemplate
              label="İdeasoft Panel Kullanıcı Adı"
              :value="formData.ideasoft_store_attributes.control_panel_username"
              keyName="formData.ideasoft_store_attributes.control_panel_username"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ideasoft_store_attributes.control_panel_username
                  .$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ideasoft_store_attributes
                .control_panel_username.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="İdeasoft Panel Kullanıcı Şifre"
              :value="formData.ideasoft_store_attributes.control_panel_password"
              keyName="formData.ideasoft_store_attributes.control_panel_password"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ideasoft_store_attributes.control_panel_password
                  .$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ideasoft_store_attributes
                .control_panel_password.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="İdeasoft Panel OAtuh CID"
              :value="formData.ideasoft_store_attributes.oauth_client_id"
              keyName="formData.ideasoft_store_attributes.oauth_client_id"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ideasoft_store_attributes.oauth_client_id.$errors
                  .length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ideasoft_store_attributes
                .oauth_client_id.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="İdeasoft Panel OAtuh CSECRET"
              :value="formData.ideasoft_store_attributes.oauth_client_secret"
              keyName="formData.ideasoft_store_attributes.oauth_client_secret"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ideasoft_store_attributes.oauth_client_secret
                  .$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ideasoft_store_attributes
                .oauth_client_secret.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="İdeasoft Store ID"
              :value="formData.ideasoft_store_attributes.ideasoft_store_id"
              keyName="formData.ideasoft_store_attributes.ideasoft_store_id"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ideasoft_store_attributes.ideasoft_store_id.$errors
                  .length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ideasoft_store_attributes
                .ideasoft_store_id.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="İdeasoft Admin Panel Url"
              :value="formData.ideasoft_store_attributes.control_panel_url"
              keyName="formData.ideasoft_store_attributes.control_panel_url"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.ideasoft_store_attributes.control_panel_url.$errors
                  .length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.ideasoft_store_attributes
                .control_panel_url.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
        </div>
      </div>
      <div class="card-body mb-20">
        <h4 class="card-sm-title">Subsription</h4>
        <div class="row">
          <div class="col-md-4">
            <TextTemplate
              label="Başlangıç Tarihi"
              :value="formData.subscription.start_date"
              keyName="formData.subscription.start_date"
              type="date"
              @onChangeText="handleText"
              :className="
                v$.formData.subscription.start_date.$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.subscription.start_date.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Plan"
              :value="formData.subscription.plan"
              keyName="formData.subscription.plan"
              type="selectID"
              :options="subscription_plan_list"
              @onChangeText="handleText"
            />
          </div>
        </div>
      </div>
      <div class="card-body mb-20">
        <h4 class="card-sm-title">App</h4>
        <div class="row">
          <div class="col-md-4">
            <TextTemplate
              label="Ad"
              :value="formData.name"
              keyName="formData.name"
              type="text"
              :className="v$.formData.name.$errors.length > 0 ? 'error' : ''"
              @onChangeText="handleText"
            />
            <span
              v-for="error in v$.formData.name.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Yönetici Ad"
              :value="formData.administrative_contact_given_name"
              keyName="formData.administrative_contact_given_name"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.administrative_contact_given_name.$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.administrative_contact_given_name
                .$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Yönetici Soyad"
              :value="formData.administrative_contact_family_name"
              keyName="formData.administrative_contact_family_name"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.administrative_contact_family_name.$errors.length >
                0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.administrative_contact_family_name
                .$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Yönetici Email"
              :value="formData.administrative_contact_email"
              keyName="formData.administrative_contact_email"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.administrative_contact_email.$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.administrative_contact_email.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Yönetici Telefon"
              :value="formData.administrative_contact_mobile_phone"
              keyName="formData.administrative_contact_mobile_phone"
              type="text"
              mask="phone"
              @onChangeText="handleText"
              :className="
                v$.formData.administrative_contact_mobile_phone.$errors.length >
                0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.administrative_contact_mobile_phone
                .$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Firebase PS Key"
              :value="formData.firebase_project_server_key"
              keyName="formData.firebase_project_server_key"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.firebase_project_server_key.$errors.length > 0
                  ? 'error'
                  : ''
              "
            />
            <span
              v-for="error in v$.formData.firebase_project_server_key.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Version"
              :value="formData.version"
              keyName="formData.version"
              type="text"
              @onChangeText="handleText"
              :className="v$.formData.version.$errors.length > 0 ? 'error' : ''"
            />
            <span
              v-for="error in v$.formData.version.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <TextTemplate
              label="Kısa Açıklama"
              :value="formData.short_description"
              keyName="formData.short_description"
              type="text"
              @onChangeText="handleText"
              :className="
                v$.formData.short_description.$errors.length > 0 ? 'error' : ''
              "
            />
            <span
              v-for="error in v$.formData.short_description.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-12">
            <TextTemplate
              label="Uzun Açıklama"
              :value="formData.long_description"
              keyName="formData.long_description"
              type="textarea"
              @onChangeText="handleText"
              :className="
                v$.formData.long_description.$errors.length > 0 ? 'error' : ''
              "
            />
            <span
              v-for="error in v$.formData.long_description.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <UploadTemplate
              @file-committed="handleFileCommit"
              label="Logo"
              itemName="formData.logo"
              :alReadyUploadedFileName="formData.logo"
              :appId="appId"
            />
          </div>
          <div class="col-md-4">
            <UploadTemplate
              @file-committed="handleFileCommit"
              @toggleEditor="toggleEditor('js')"
              label="JS Inject"
              itemName="formData.js_injection_file"
              :alReadyUploadedFileName="formData.js_injection_file"
              :edit="true"
              :appId="appId"
            />
          </div>
          <div class="col-md-4">
            <UploadTemplate
              @file-committed="handleFileCommit"
              @toggleEditor="toggleEditor('json')"
              label="JSON Config"
              itemName="formData.json_config_file"
              :alReadyUploadedFileName="formData.json_config_file"
              :edit="true"
              :appId="appId"
            />
          </div>
          <div class="col-md-12" v-if="jsEditor">
            <div class="title">JS Editor</div>
            <div class="code-edit-container">
              <button
                class="btn btn-empty-primary w-auto minw-auto save-file"
                @click="saveFile('js')"
              >
                <i class="icon icon-tick-square"></i>
              </button>
              <CodeEditor
                border_radius="8px"
                theme="light"
                width="100%"
                v-model="jsContent"
              ></CodeEditor>
            </div>
          </div>
          <div class="col-md-12" v-if="jsonEditor">
            <div class="title">JSON Editor</div>
            <div class="code-edit-container">
              <button
                class="btn btn-empty-primary w-auto minw-auto save-file"
                @click="saveFile('json')"
              >
                <i class="icon icon-tick-square"></i>
              </button>
              <CodeEditor
                border_radius="8px"
                theme="light"
                width="100%"
                :display_language="true"
                v-model="jsonContent"
              ></CodeEditor>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <h4 class="card-sm-title">Platform Seçiniz</h4>
        <div class="tabs">
          <div class="tabs-head">
            <div
              class="tab"
              :class="{ active: activeTab === 0 }"
              @click="setActiveTab(0)"
            >
              IOS
            </div>
            <div
              class="tab"
              :class="{ active: activeTab === 1 }"
              @click="setActiveTab(1)"
            >
              Android
            </div>
          </div>
          <div class="tabs-container">
            <div class="tab" :class="{ active: activeTab === 0 }">
              <div class="row">
                <div class="col-md-4">
                  <TextTemplate
                    label="Team ID"
                    type="text"
                    :value="formData.ios_attributes.team_id"
                    keyName="formData.ios_attributes.team_id"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.ios_attributes.team_id.$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes.team_id.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Account Holder Email"
                    type="text"
                    :value="formData.ios_attributes.account_holder_email"
                    keyName="formData.ios_attributes.account_holder_email"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.ios_attributes.account_holder_email.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes
                      .account_holder_email.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Bundle ID"
                    type="text"
                    :value="formData.ios_attributes.bundle_id"
                    keyName="formData.ios_attributes.bundle_id"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.ios_attributes.bundle_id.$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes.bundle_id
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Version"
                    type="text"
                    :value="formData.ios_attributes.version"
                    keyName="formData.ios_attributes.version"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.ios_attributes.version.$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes.version.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Test Kullanıcı Adı"
                    type="text"
                    :value="formData.ios_attributes.testuser_username"
                    keyName="formData.ios_attributes.testuser_username"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.ios_attributes.testuser_username.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes.testuser_username
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Test Kullanıcı Şifre"
                    type="text"
                    :value="formData.ios_attributes.testuser_password"
                    keyName="formData.ios_attributes.testuser_password"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.ios_attributes.testuser_password.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes.testuser_password
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Apple Developer Hesabı"
                    :value="formData.ios_attributes.apple_developer_account"
                    keyName="formData.ios_attributes.apple_developer_account"
                    type="selectID"
                    :options="apple_developer_account_list"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.ios_attributes.apple_developer_account.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes
                      .apple_developer_account.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Uygulama Durumu"
                    :value="formData.ios_attributes.status"
                    keyName="formData.ios_attributes.status"
                    type="select"
                    :options="statuses"
                    @onChangeText="handleText"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="Private Key"
                    itemName="formData.ios_attributes.private_key"
                    :alReadyUploadedFileName="
                      formData.ios_attributes.private_key
                    "
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes.private_key.$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes.private_key
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="Csr File"
                    itemName="formData.ios_attributes.csr_file"
                    :alReadyUploadedFileName="formData.ios_attributes.csr_file"
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes.csr_file.$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes.csr_file.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="Development Cer"
                    itemName="formData.ios_attributes.development_certificate"
                    :alReadyUploadedFileName="
                      formData.ios_attributes.development_certificate
                    "
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes.development_certificate.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes
                      .development_certificate.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="Distribution Cer"
                    itemName="formData.ios_attributes.distribution_certificate"
                    :alReadyUploadedFileName="
                      formData.ios_attributes.distribution_certificate
                    "
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes.distribution_certificate
                        .$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes
                      .distribution_certificate.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="APNS Cer"
                    itemName="formData.ios_attributes.apns_certificate"
                    :alReadyUploadedFileName="
                      formData.ios_attributes.apns_certificate
                    "
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes.apns_certificate.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes.apns_certificate
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="Firebase APNS Cer"
                    itemName="formData.ios_attributes.firebase_apns_certificate"
                    :alReadyUploadedFileName="
                      formData.ios_attributes.firebase_apns_certificate
                    "
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes.firebase_apns_certificate
                        .$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes
                      .firebase_apns_certificate.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="Development Profile"
                    itemName="formData.ios_attributes.development_provisioning_profile"
                    :alReadyUploadedFileName="
                      formData.ios_attributes.development_provisioning_profile
                    "
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes
                        .development_provisioning_profile.$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes
                      .development_provisioning_profile.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="Ad Hoc Profile"
                    itemName="formData.ios_attributes.ad_hoc_provisioning_profile"
                    :alReadyUploadedFileName="
                      formData.ios_attributes.ad_hoc_provisioning_profile
                    "
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes.ad_hoc_provisioning_profile
                        .$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes
                      .ad_hoc_provisioning_profile.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="App Store Profile"
                    itemName="formData.ios_attributes.app_store_provisioning_profile"
                    :alReadyUploadedFileName="
                      formData.ios_attributes.app_store_provisioning_profile
                    "
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes.app_store_provisioning_profile
                        .$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes
                      .app_store_provisioning_profile.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="Firebase Config File"
                    itemName="formData.ios_attributes.ios_firebase_configuration_file"
                    :alReadyUploadedFileName="
                      formData.ios_attributes.ios_firebase_configuration_file
                    "
                    :appId="appId"
                    :className="
                      v$.formData.ios_attributes.ios_firebase_configuration_file
                        .$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.ios_attributes
                      .ios_firebase_configuration_file.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
              </div>
            </div>
            <div class="tab" :class="{ active: activeTab === 1 }">
              <div class="row">
                <div class="col-md-4">
                  <TextTemplate
                    label="Developer ID"
                    :value="formData.android_attributes.google_developer_id"
                    keyName="formData.android_attributes.google_developer_id"
                    type="text"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.google_developer_id.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes
                      .google_developer_id.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Package Name"
                    :value="formData.android_attributes.package_name"
                    keyName="formData.android_attributes.package_name"
                    type="text"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.package_name.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes.package_name
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Version Code"
                    :value="formData.android_attributes.version_code"
                    keyName="formData.android_attributes.version_code"
                    type="text"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.version_code.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes.version_code
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Version Name"
                    :value="formData.android_attributes.version_name"
                    keyName="formData.android_attributes.version_name"
                    type="text"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.version_name.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes.version_name
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Key Alias"
                    :value="formData.android_attributes.key_alias"
                    keyName="formData.android_attributes.key_alias"
                    type="text"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.key_alias.$errors.length >
                      0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes.key_alias
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Keystore Password"
                    :value="formData.android_attributes.keystore_password"
                    keyName="formData.android_attributes.keystore_password"
                    type="text"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.keystore_password.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes
                      .keystore_password.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Key Password"
                    :value="formData.android_attributes.key_password"
                    keyName="formData.android_attributes.key_password"
                    type="text"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.key_password.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes.key_password
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Test Kullanıcı Adı"
                    :value="formData.android_attributes.testuser_username"
                    keyName="formData.android_attributes.testuser_username"
                    type="text"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.testuser_username.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes
                      .testuser_username.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Test Kullanıcı Şifre"
                    :value="formData.android_attributes.testuser_password"
                    keyName="formData.android_attributes.testuser_password"
                    type="text"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.testuser_password.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes
                      .testuser_password.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Google Developer Hesabı"
                    :value="
                      formData.android_attributes.google_developer_account
                    "
                    keyName="formData.android_attributes.google_developer_account"
                    type="selectID"
                    :options="google_developer_account_list"
                    @onChangeText="handleText"
                    :className="
                      v$.formData.android_attributes.google_developer_account
                        .$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes
                      .google_developer_account.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <TextTemplate
                    label="Uygulama Durumu"
                    :value="formData.android_attributes.status"
                    keyName="formData.android_attributes.status"
                    type="select"
                    :options="statuses"
                    @onChangeText="handleText"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="JKS"
                    itemName="formData.android_attributes.java_keystore"
                    :alReadyUploadedFileName="
                      formData.android_attributes.java_keystore
                    "
                    :appId="appId"
                    :className="
                      v$.formData.android_attributes.java_keystore.$errors
                        .length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes.java_keystore
                      .$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
                <div class="col-md-4">
                  <UploadTemplate
                    @file-committed="handleFileCommit"
                    label="Firebase File"
                    itemName="formData.android_attributes.android_firebase_configuration_file"
                    :alReadyUploadedFileName="
                      formData.android_attributes
                        .android_firebase_configuration_file
                    "
                    :appId="appId"
                    :className="
                      v$.formData.android_attributes
                        .android_firebase_configuration_file.$errors.length > 0
                        ? 'error'
                        : ''
                    "
                  />
                  <span
                    v-for="error in v$.formData.android_attributes
                      .android_firebase_configuration_file.$errors"
                    :key="error.$uid"
                    class="errMsg"
                    >{{ error.$message }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        class="btn btn-filled-primary ml-auto mt-20"
        @click="updateApp"
      >
        Uygulama Güncelle
      </button>
    </div>
  </div>
  <Done
    @closeModal="doneModalClose"
    v-if="isCloseModal"
    :doneMsg="popupText"
    :doneTitle="popupTitle"
    link="/apps"
  />
</template>
<script>
import useValidate from "@vuelidate/core";
import { required, helpers, requiredIf, email } from "@vuelidate/validators";

export default {
  data() {
    return {
      appId: this.$route.params.id,
      activeTab: 1,
      isCloseModal: false,
      popupText: "",
      popupTitle: "",
      numberMask: {
        mask: ["#############################################################"],
        tokens: {
          "#": { pattern: /[0-9]/ },
        },
      },
      httpMask: {
        mask: ["http*://", "http://"],
        tokens: {
          "*": { pattern: /[s]/ },
        },
      },
      versionNumberMask: {
        mask: ["##"],
        tokens: {
          "#": { pattern: /[0-9]/ },
        },
      },
      maskAlphaNumeric: {
        mask: ["#############################################################"],
        tokens: {
          "#": { pattern: /[a-zA-Z0-9]/ },
        },
      },
      v$: useValidate(),
      jsContent: "",
      jsonContent: "",
      jsEditor: false,
      jsonEditor: false,
      formData: {
        ecommerce_store: {
          store_url: "",
          security_privacy_policy_url: "",
          support_url: "",
          contact_email: "",
          ecommerce_platform: "",
          customer: "",
        },
        ideasoft_store_attributes: {
          control_panel_username: "",
          control_panel_password: "",
          oauth_client_id: "",
          oauth_client_secret: "",
          ideasoft_store_id: "",
          control_panel_url: "",
        },
        subscription: {
          plan: "",
          start_date: "",
          customer: "",
        },
        name: "",
        version: "",
        administrative_contact_given_name: "",
        administrative_contact_family_name: "",
        administrative_contact_email: "",
        administrative_contact_mobile_phone: "",
        firebase_project_server_key: "",
        short_description: "",
        long_description: "",
        logo: "",
        js_injection_file: "",
        json_config_file: "",
        ios_attributes: {
          team_id: "",
          account_holder_email: "",
          bundle_id: "",
          version: "",
          testuser_username: "",
          testuser_password: "",
          apple_developer_account: "",
          private_key: "",
          csr_file: "",
          development_certificate: "",
          distribution_certificate: "",
          apns_certificate: "",
          firebase_apns_certificate: "",
          development_provisioning_profile: "",
          ad_hoc_provisioning_profile: "",
          app_store_provisioning_profile: "",
          ios_firebase_configuration_file: "",
        },
        android_attributes: {
          google_developer_id: "",
          package_name: "",
          version_code: "",
          version_name: "",
          key_alias: "",
          keystore_password: "",
          key_password: "",
          testuser_username: "",
          testuser_password: "",
          google_developer_account: "",
          java_keystore: "",
          android_firebase_configuration_file: "",
        },
      },
      ecommerce_platform_list: [],
      customer_list: [],
      subscription_plan_list: [],
      google_developer_account_list: [],
      apple_developer_account_list: [],
      manipulatedData: {},
      pageloaded: false,
      statuses: [
        "inactive",
        "ready_to_build",
        "created",
        "waiting_for_review",
        "published",
      ],
    };
  },

  validations() {
    return {
      formData: {
        name: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        administrative_contact_given_name: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        administrative_contact_family_name: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        administrative_contact_email: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          email: helpers.withMessage(
            "Lütfen geçerli bir e-mail giriniz.",
            email
          ),
          $autoDirty: true,
        },
        administrative_contact_mobile_phone: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        firebase_project_server_key: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        version: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        logo: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        short_description: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        long_description: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        android_attributes: {
          google_developer_id: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          google_developer_account: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          package_name: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          version_code: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          version_name: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          key_alias: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          keystore_password: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          key_password: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          testuser_username: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            email: helpers.withMessage(
              "Lütfen geçerli bir e-mail giriniz.",
              email
            ),
            $autoDirty: true,
          },
          testuser_password: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          java_keystore: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          android_firebase_configuration_file: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.android_attributes) {
                  if (this.formData.android_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
        },
        ios_attributes: {
          team_id: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          account_holder_email: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            email: helpers.withMessage(
              "Lütfen geçerli bir e-mail giriniz.",
              email
            ),
            $autoDirty: true,
          },
          bundle_id: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          version: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          testuser_username: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            email: helpers.withMessage(
              "Lütfen geçerli bir e-mail giriniz.",
              email
            ),
            $autoDirty: true,
          },
          testuser_password: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          apple_developer_account: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          private_key: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          csr_file: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          development_certificate: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          distribution_certificate: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          apns_certificate: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          firebase_apns_certificate: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          development_provisioning_profile: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          ad_hoc_provisioning_profile: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          app_store_provisioning_profile: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
          ios_firebase_configuration_file: {
            required: helpers.withMessage(
              "Bu alan zorunlu",
              requiredIf(function (value, vm) {
                let hasValue = false;
                for (let value in this.formData.ios_attributes) {
                  if (this.formData.ios_attributes[value] !== "")
                    hasValue = true;
                }
                return hasValue;
              })
            ),
            $autoDirty: true,
          },
        },
        ecommerce_store: {
          contact_email: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            email: helpers.withMessage(
              "Lütfen geçerli bir e-mail giriniz.",
              email
            ),
            $autoDirty: true,
          },
          customer: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
          security_privacy_policy_url: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
          store_url: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
          support_url: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
        },
        ideasoft_store_attributes: {
          control_panel_password: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
          control_panel_username: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
          ideasoft_store_id: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
          oauth_client_id: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
          oauth_client_secret: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
          control_panel_url: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
        },
        subscription: {
          start_date: {
            required: helpers.withMessage("Bu alan zorunlu", required),
            $autoDirty: true,
          },
        },
      },
    };
  },
  async created() {
    await this.getCustomers().then(
      (customers) => (this.customer_list = customers)
    );
    await this.getEcommercePlatforms().then(
      (platforms) => (this.ecommerce_platform_list = platforms)
    );
    await this.getSubscriptionPlans().then(
      (plans) => (this.subscription_plan_list = plans)
    );

    this.getApp();

    this.$store.commit("theme/addToBreadcrumps", [
      {
        title: "Anasayfa",
        path: "/",
      },
      {
        title: "Mobil Uygulamalar",
        path: "/apps",
      },
      {
        title: "Mobil Uygulama Detay",
      },
      {
        title: `${this.$route.params.id}`,
      },
    ]);
  },
  watch: {
    "formData.ecommerce_store.customer": function () {
      if (this.formData.ecommerce_store.customer) {
        this.getGoogleDeveloperAccounts();
        this.getAppleDeveloperAccounts();
        this.formData.subscription.customer = Number(
          this.formData.ecommerce_store.customer.id
        );
      }
    },
  },
  props: {
    id: String,
  },
  methods: {
    doneModalClose(value) {
      this.isCloseModal = value;
    },
    setActiveTab(index) {
      this.activeTab = index;
    },
    async getEcommercePlatforms() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/ecommerce-platform/")
          .then((response) => {
            let platforms = response.data;
            let ecommerce_platform_list = platforms.map((platform) => ({
              id: platform.id,
              name: platform.name,
            }));
            resolve(ecommerce_platform_list);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getCustomers() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/customer/")
          .then((response) => {
            let customers = response.data;
            let customer_list = customers.map((customer) => ({
              id: customer.id,
              name: customer.name,
            }));
            resolve(customer_list);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getSubscriptionPlans() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/subscription-plan/")
          .then((response) => {
            let plans = response.data;
            let subscription_plan_list = plans.map((plan) => ({
              id: plan.id,
              name: plan.name,
            }));
            resolve(subscription_plan_list);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    toggleEditor(type) {
      if (type == "js") {
        this.jsonEditor = false;
        this.jsEditor = !this.jsEditor;
      }
      if (type == "json") {
        this.jsonEditor = !this.jsonEditor;
        this.jsEditor = false;
      }
    },

    async saveFile(type) {
      let file;
      let convertedFile = {};
      if (type == "js") {
        file = new File([this.jsContent], "inject.js", {
          type: "text/javascript",
        });
        await this.convertBase64(file).then((resolveBase64WithName) => {
          convertedFile = {
            js_injection_file: resolveBase64WithName,
          };
        });
      }
      if (type == "json") {
        file = new File([this.jsonContent], "config.json", {
          type: "application/json",
        });
        await this.convertBase64(file).then((resolveBase64WithName) => {
          convertedFile = {
            json_config_file: resolveBase64WithName,
          };
        });
      }

      await this.$axios
        .patch(`/mobile-app/${this.appId}/`, convertedFile)
        .then(async () => {
          await this.getApp();
          this.popupText =
            "Uygulama güncelleme işlemi başarıyla gerçekleştirildi.";
          this.popupTitle = "İşlem Başarılı!";
          this.isCloseModal = true;
          this.jsEditor = false;
          this.jsonEditor = false;
        });
    },

    fetchFile(url, content) {
      const fileNumber = Math.floor(1000000000 + Math.random() * 9000000000)
        fetch(url + '?id=' + fileNumber)
            .then(response => response.text())
            .then(result => this[content] = result)
            .catch(error => console.log('error', error));

            console.log(url + '?id=' + fileNumber)
    },

    async getApp() {
      await this.$axios.get(`/mobile-app/${this.appId}/`).then((response) => {
        let app = response.data;
        this.formData = {
          ecommerce_store: {
            store_url: app.ecommerce_store.store_url,
            security_privacy_policy_url:
              app.ecommerce_store.security_privacy_policy_url,
            support_url: app.ecommerce_store.support_url,
            contact_email: app.ecommerce_store.contact_email,
            customer: app.ecommerce_store.customer,
            ecommerce_platform: app.ecommerce_store.ecommerce_platform,
          },
          ideasoft_store_attributes: app.ideasoft_store_attributes
            ? app.ideasoft_store_attributes
            : {},
          subscription: {
            plan: app.subscription.plan,
            start_date: app.subscription.start_date,
            customer: app.subscription.customer,
          },
          name: app.name,
          version: app.version,
          administrative_contact_given_name:
            app.administrative_contact_given_name,
          administrative_contact_family_name:
            app.administrative_contact_family_name,
          administrative_contact_email: app.administrative_contact_email,
          administrative_contact_mobile_phone:
            app.administrative_contact_mobile_phone,
          firebase_project_server_key: app.firebase_project_server_key,
          short_description: app.short_description,
          long_description: app.long_description,
          logo: app.logo,
          js_injection_file: app.js_injection_file,
          json_config_file: app.json_config_file,
          ios_attributes: app.ios_attributes
            ? {
                team_id: app.ios_attributes.team_id,
                account_holder_email: app.ios_attributes.account_holder_email,
                bundle_id: app.ios_attributes.bundle_id,
                version: app.ios_attributes.version,
                testuser_username: app.ios_attributes.testuser_username,
                testuser_password: app.ios_attributes.testuser_password,
                apple_developer_account:
                  app.ios_attributes.apple_developer_account,
                private_key: app.ios_attributes.private_key,
                csr_file: app.ios_attributes.csr_file,
                development_certificate:
                  app.ios_attributes.development_certificate,
                distribution_certificate:
                  app.ios_attributes.distribution_certificate,
                apns_certificate: app.ios_attributes.apns_certificate,
                firebase_apns_certificate:
                  app.ios_attributes.firebase_apns_certificate,
                development_provisioning_profile:
                  app.ios_attributes.development_provisioning_profile,
                ad_hoc_provisioning_profile:
                  app.ios_attributes.ad_hoc_provisioning_profile,
                app_store_provisioning_profile:
                  app.ios_attributes.app_store_provisioning_profile,
                ios_firebase_configuration_file:
                  app.ios_attributes.ios_firebase_configuration_file,
                status: app.ios_attributes.status,
              }
            : {},
          android_attributes: {
            google_developer_id: app.android_attributes.google_developer_id,
            package_name: app.android_attributes.package_name,
            version_code: app.android_attributes.version_code,
            version_name: app.android_attributes.version_name,
            key_alias: app.android_attributes.key_alias,
            keystore_password: app.android_attributes.keystore_password,
            key_password: app.android_attributes.key_password,
            testuser_username: app.android_attributes.testuser_username,
            testuser_password: app.android_attributes.testuser_password,
            google_developer_account:
              app.android_attributes.google_developer_account,
            java_keystore: app.android_attributes.java_keystore,
            android_firebase_configuration_file:
              app.android_attributes.android_firebase_configuration_file,
            status: app.android_attributes.status,
          },
        };

        this.fetchFile(
          this.$env.stage.API_URL + `${app.js_injection_file}`,
          "jsContent"
        );
        this.fetchFile(
          this.$env.stage.API_URL + `${app.json_config_file}`,
          "jsonContent"
        );

        this.formData.ecommerce_store.customer = this.customer_list.find(
          (customer) => customer.id == app.ecommerce_store.customer
        );
      });
    },

    getGoogleDeveloperAccounts() {
      this.$axios
        .get(
          `/customer/${this.formData.ecommerce_store.customer.id}/developer-account/google/`
        )
        .then((response) => {
          let accounts = response.data;
          this.google_developer_account_list = [];
          accounts.filter((account) => {
            this.google_developer_account_list.push({
              id: account.id,
              name: account.name,
            });
          });
        });
    },
    getAppleDeveloperAccounts() {
      this.$axios
        .get(
          `/customer/${this.formData.ecommerce_store.customer.id}/developer-account/apple/`
        )
        .then((response) => {
          let accounts = response.data;
          this.apple_developer_account_list = [];
          accounts.filter((account) => {
            this.apple_developer_account_list.push({
              id: account.id,
              name: account.name,
            });
          });
        });
    },

    convertBase64(file) {
      return new Promise((resolve, reject) => {
        if (file) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const base64String = e.target.result.split(",")[1];
            const resolveBase64WithName = `data:${
              file.type == "" ? "text/plain" : file.type
            };'${file.name}';base64,${base64String}`;
            resolve(resolveBase64WithName);
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsDataURL(file);
        } else {
          reject(new Error("No file provided"));
        }
      });
    },

    async handleUploadedFile(formData, field, postData) {
      let fields = field.split(".");
      if (fields.length > 1) {
        if (formData[fields[0]]) {
          if (formData[fields[0]][fields[1]]) {
            await this.convertBase64(formData[fields[0]][fields[1]]).then(
              (resolveBase64WithName) => {
                postData[fields[0]][fields[1]] = resolveBase64WithName;
              }
            );
          }
        }
      } else {
        if (formData[fields[0]]) {
          await this.convertBase64(formData[fields[0]]).then(
            (resolveBase64WithName) => {
              postData[fields[0]] = resolveBase64WithName;
            }
          );
        }
      }
    },

    async updateApp() {
      let postData = JSON.parse(JSON.stringify(this.manipulatedData.formData));

      let files = [
        "logo",
        "js_injection_file",
        "json_config_file",
        "android_attributes.java_keystore",
        "android_attributes.android_firebase_configuration_file",
        "ios_attributes.private_key",
        "ios_attributes.csr_file",
        "ios_attributes.development_certificate",
        "ios_attributes.distribution_certificate",
        "ios_attributes.apns_certificate",
        "ios_attributes.firebase_apns_certificate",
        "ios_attributes.development_provisioning_profile",
        "ios_attributes.ad_hoc_provisioning_profile",
        "ios_attributes.app_store_provisioning_profile",
        "ios_attributes.ios_firebase_configuration_file",
      ];

      for (let file of files) {
        await this.handleUploadedFile(
          this.manipulatedData.formData,
          file,
          postData
        );
      }
      this.v$.$touch();
      if (!this.v$.$error) {
        this.$axios.patch(`/mobile-app/${this.appId}/`, postData)
        .then(async () => {
          await this.getApp();
          this.manipulatedData = [];
          this.popupText =
            "Uygulama ekleme işlemi başarıyla gerçekleştirildi. Oluşturulan uygulamaları görmek için Uygulamalar sekmesine gidebilirsiniz.";
          this.popupTitle = "İşlem Başarılı!";
          this.isCloseModal = true;
        });
      }
    },

    handleFileCommit(data) {
      let keynames = data.name.split(".");
      let target = this; // Başlangıç noktası, this (genellikle Vue bileşeni)
      let targetn = this.manipulatedData; // Başlangıç noktası, this (genellikle Vue bileşeni)
      this.convertBase64(data.file);
      for (let i = 0; i < keynames.length; i++) {
        if (i === keynames.length - 1) {
          target[keynames[i]] = data.file; // Son adımda data.value'yu atama
          targetn[keynames[i]] = data.file; // Son adımda data.value'yu atama
        } else {
          if (!target[keynames[i]]) {
            target[keynames[i]] = {}; // Eğer yol henüz tanımlanmamışsa boş bir nesne oluşturma
          }
          if (!targetn[keynames[i]]) {
            targetn[keynames[i]] = {}; // Eğer yol henüz tanımlanmamışsa boş bir nesne oluşturma
          }
          target = target[keynames[i]]; // Yolu takip et
          targetn = targetn[keynames[i]]; // Yolu takip et
        }
      }
    },
    handleText(data) {
      let keynames = data.keyName.split(".");
      let target = this; // Başlangıç noktası, this (genellikle Vue bileşeni)
      let targetn = this.manipulatedData; // Başlangıç noktası, this (genellikle Vue bileşeni)

      for (let i = 0; i < keynames.length; i++) {
        if (i === keynames.length - 1) {
          target[keynames[i]] = data.value; // Son adımda data.value'yu atama
          targetn[keynames[i]] = data.value;
        } else {
          if (!target[keynames[i]]) {
            target[keynames[i]] = {};
          }
          if (!targetn[keynames[i]]) {
            targetn[keynames[i]] = {};
          }
          target = target[keynames[i]]; // Yolu takip et
          targetn = targetn[keynames[i]]; // Yolu takip et
        }
      }
    },
  },
};
</script>
